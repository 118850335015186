/* Define common CSS variables for color scheme */
:root {
  --background-color: #1A1916;
  --content-background-color: #f8f8f8;
  --border-color: #1A1916;
  --text-color: #333333;
  --font-family: 'Helvetica Neue', Arial, sans-serif;
  --header-font-family: 'Freeman', sans-serif;
  --header-title-color: lightgray;
  --header-background-image: url('./assets/header-logo-bg-16.png'); /* Add the path to your light mode image */
  --parchment-color: #f2e0c9; /* Light parchment paper color */
  --linaius-yellow: #eab676;
  --big-font-size: 1.5rem;
  --big-font-weight: bold;
  --small-font-size: 12px;
  --header-font-size: 2em;
  --paragraph-font-size: 1.2em;
}

/*******************************/
/**** Apply global styles ******/
/*******************************/

body {  
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
}

/*******************************/
/* Style the header            */
/*******************************/

/* Header-specific variables */
header {
  --header-text-shadow-color: rgba(0, 0, 0, 0.1);

  width: 80%;
  text-align: center;
  position: relative; /* Ensure the header is the containing block for the absolute positioning */
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 40px;
  margin-top: 40px;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--header-font-family); /* Use the Montserrat font for the header */
  background-image: var(--header-background-image);
  z-index: 0;
  box-shadow: 4px 2px 4px var(--header-text-shadow-color); /* Optional: Add box shadow for a subtle effect */
}

if_needed_rename_to_header h1::before {
    content: '';
    position: absolute;
    bottom: -10px; /* Adjust to position the box correctly */
    right: -10px; /* Adjust to position the box correctly */
    width: calc(100% + 20px); /* Adjust to fit the size of the h1 */
    height: calc(100% + 20px); /* Adjust to fit the size of the h1 */
    border-radius: 5px;
    background-color: var(--parchment-color);
    z-index: -1; /* Ensure the box is behind the text */
}

header h1 {
  margin: 0;
  padding-right: 45px;
  font-size: var(--header-font-size);
  color: var(--header-title-color) ; /* Ensure text is readable over the background image */
  position: absolute;
  bottom: 10px; /* Adjust as necessary */
  right: 10px; /* Adjust as necessary */
  font-family: var(--header-font-family); /* Use the Montserrat font for the h1 */
}

header p {
  margin: 0;
  font-size: var(--paragraph-font-size);
  color: #666666;
  font-family: var(--header-font-family); /* Use the Montserrat font for the h1 */
}

/*******************************/
/* Style the main content area */
/*******************************/

.main-content {
  background-color: var(--content-background-color);
  border-radius: 10px;
  max-width: 2000px; /* 1012px;*/
  min-width: 1300px;
  width: 80%;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 2px solid var(--border-color);
  border-right: 2px solid var(--border-color);
}

h1 {
  margin-top: 0;
  font-size: 2em;
}

p {
  margin-bottom: 0;
  font-size: var(--paragraph-font-size);
}

/*******************************/
/* Style the rounded rectangle */
/*******************************/

.rounded-rectangle {
  --rounded-rectangle-color: #4a90e2;
  --rounded-rectangle-text-color: white;
  --rounded-rectangle-font-size: 1.5em;

  width: 200px;
  height: 100px;
  background-color: var(--rounded-rectangle-color);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--rounded-rectangle-text-color);
  font-size: var(--rounded-rectangle-font-size);
}

/*******************************/
/* Style the header banner     */
/*******************************/

.header-banner {
  --header-banner-font-size: 100px;
  --header-banner-background-gradient: linear-gradient(145deg, #eeeeee, #cccccc);
  --header-banner-shadow-dark: rgba(0, 0, 0, 0.1);
  --header-banner-shadow-light: rgba(255, 255, 255, 0.5);
  --header-banner-shadow-mid: rgba(0, 0, 0, 0.2);

  position: relative;
  text-align: center;
  font-family: 'Oswald', sans-serif; /* Use the chosen web font */
  color: var(--header-title-color);
}

.header-banner h1 {
  font-family: 'Oswald', sans-serif; /* Use the chosen web font */
  color: var(--header-title-color);
  text-align: center;
  font-size: var(--header-banner-font-size);
  background: var(--header-banner-background-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 
    1px  1px  1px var(--header-banner-shadow-dark),
    -1px -1px  1px var(--header-banner-shadow-light),
    0    1px  1px var(--header-banner-shadow-mid),
    0   -1px  1px var(--header-banner-shadow-light);
  position: relative;
}

/*******************************/
/* Style the logo              */
/*******************************/

.logo {
  --logo-color: #333333;
  --logo-main-font-size: 96px;
  --logo-main-font-weight: bold;
  --logo-main-text-transform: uppercase;
  --logo-main-letter-spacing: 0.1em;
  --logo-main-shadow: rgba(0, 0, 0, 0.1);
  --logo-sub-font-size: 24px;
  --logo-sub-color: #3333cc;
  --logo-sub-margin-top: -20px;

  font-family: 'Oswald', sans-serif; /* Use the chosen web font */
  color: var(--logo-color); /* Dark gray color for "LINAIUS" */
  text-align: center;
}

.logo .main {
  font-size: var(--logo-main-font-size); /* Large font size for "LINAIUS" */
  font-weight: var(--logo-main-font-weight);
  text-transform: var(--logo-main-text-transform); /* Make the text uppercase */
  letter-spacing: var(--logo-main-letter-spacing); /* Add a bit of spacing between letters */
  position: relative; /* Positioning for shadow effect */
  text-shadow: 2px 2px 5px var(--logo-main-shadow); /* Adding shadow effect for lift */
}

.logo .sub {
  font-size: var(--logo-sub-font-size); /* Smaller font size for "Data Science" */
  color: var(--logo-sub-color); /* Dark purple color for "Data Science" */
  margin-top: var(--logo-sub-margin-top); /* Adjust vertical position */
  letter-spacing: var(--logo-main-letter-spacing); /* Add a bit of spacing between letters */
}

/*******************************/
/* CSS For Top Stats Component */
/*******************************/

.grid {
  --grid-gap: 1rem;
  --grid-padding: 1rem;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--grid-gap);
  padding: var(--grid-padding); /* Add padding around the entire grid */
}

.card {
  --card-padding: 1rem;
  --card-border-radius: 0.5rem;
  --card-shadow-color: rgba(100, 100, 100, 0.1);
  --card-margin: 20px;
  margin: var(--card-margin);

  padding: var(--card-padding);
  border: 1px solid #CBC3E3;
  border-radius: var(--card-border-radius);
  text-align: center;
  box-shadow: 4px 4px 4px var(--card-shadow-color); /* Optional: Add box shadow for a subtle effect */
}

.card h2 {
  margin-bottom: 0.5rem;
}

.card .big {
  font-size: var(--big-font-size);
  font-weight: var(--big-font-weight);
}

/*******************************/
/* CSS For World Map Component */
/*******************************/

.map_container {
  --map-text-color: black;

  padding: var(--grid-padding);
  color: var(--map-text-color);
}

#projection_map {
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.pie_container {
  padding: var(--grid-padding);
}

/*******************************/
/**** making two column work ***/
/*******************************/

.chart-container {
  --chart-gap: 0rem;

  display: grid;
  grid-template-columns: 1fr 1fr; /* Create two equal columns */
  gap: var(--chart-gap);
  width: 100%;
}

.chart-item {
  background-color: var(--content-background-color);
  border-radius: var(--card-border-radius);
}

.bar-graph,
.bar-chart-container {
  --bar-chart-max-width: 600px;
  --bar-chart-margin: 20px;

  width: 80%;
  max-width: var(--bar-chart-max-width);
  margin: var(--bar-chart-margin);
}

#view-select {
}

.sunburst-container,
.radial-tree-container {
  margin: 20px;
}

/* Old month calendar */ 

.calendar-container {
  --calendar-container-margin: 0 0 0 30px;
  --calendar-container-gap: 10px;

  margin: var(--calendar-container-margin);
  display: flex;
  justify-content: space-between;
  width: 90%;
  gap: var(--calendar-container-gap); /* Add gap between months */
}

.month {
  --month-width: 100;
  --month-padding: 20px;
  --month-border-radius: 10px;
  --month-hover-scale: 1.1;

  width: var(--month-width); /* Increase the width of the rectangles */
  padding: var(--month-padding); /* Increase the padding for a wider rectangle */
  text-align: center;
  border-radius: var(--month-border-radius);
  transition: transform 0.3s ease;
}

.month:hover {
  transform: scale(var(--month-hover-scale));
}

/* Observable10 color scheme */
.past {
  background-color: #E15759;
  color: white;
}

.future {
  background-color: #BAB0AC;
  color: white;
}

.current {
  background-color: #76B7B2;
  color: white;
}

/* end old Month calendar */ 

.calendar-ruler {
  --calendar-width: 97%;
  --calendar-height: 100px;
  --calendar-background-color: #f8f8f8;
  --calendar-border-color: #cccccc;
  --calendar-shadow-color: rgba(0, 0, 0, 0.1);
  --calendar-opacity: 80%;
  --calendar-margin: 20px 10px 20px 20px;

  position: relative;
  display: flex;
  align-items: center;
  width: var(--calendar-width);
  height: var(--calendar-height);
  background-color: var(--calendar-background-color);
  border: 1px solid var(--calendar-border-color);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  margin: var(--calendar-margin);
  opacity: var(--calendar-opacity);
  box-shadow: 0 2px 4px var(--calendar-shadow-color);
}

.month-line {
  --week-line-color: gray;

  position: relative;
  flex: 1;
  height: 100%;
  text-align: center;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.month-line:first-child {
  border-left: none;
}

.month-line:last-child {
  border-right: none;
}

.month-line:after {
  content: attr(id);
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--small-font-size);
  color: var(--calendar-text-color);
}

.week-line {
  --week-line-bottom: 0;
  --week-line-height: 25px;
  --week-line-border: 1px solid gray;

  position: absolute;
  bottom: var(--week-line-bottom);
  height: var(--week-line-height);
  border-left: var(--week-line-border);
}

.month-line.past .week-line {
  border-left: 1px solid white;
}

.month-line .week-line:nth-child(1) { left: 20%; }
.month-line .week-line:nth-child(2) { left: 40%; }
.month-line .week-line:nth-child(3) { left: 60%; }
.month-line .week-line:nth-child(4) { left: 80%; }

#jan, #feb, #mar, #apr, #may, #jun, #jul, #aug, #sep, #oct, #nov, #dec {
  position: relative;
}

/* Slider */
.slider {
  --slider-background-color: rgba(0, 0, 0, 0.1);
  --slider-border-color: rgba(0, 0, 0, 0.5);
  --slider-border-radius: 20px;

  position: absolute;
  top: -5px;
  left: 0;
  width: 150px;
  height: 110px;
  background-color: var(--slider-background-color);
  border: 1px solid var(--slider-border-color);
  transition: left 0.3s ease;
  border-radius: var(--slider-border-radius);
}

/* Reactive table */
.reactive-table {
  --reactive-table-margin: 20px;
  --reactive-table-width: 94%;
  --reactive-table-background-color: white;
  --reactive-table-height: 490px !important;
  
  margin: var(--reactive-table-margin);
  height: var(--reactive-table-height);
  width:  var(--reactive-table-width);
  background-color: var(--reactive-table-background-color);
}



